import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom'
import './index.scss'

export default function Home() {
  const location = useLocation();
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (location.pathname === '/app') {
    if (/android/i.test(userAgent)) {
      window.location.replace('https://play.google.com/store/apps/details?id=com.thirdrailplatform.wott&hl=en-US&ah=F6tzfVcrotp-66gEpo7HG6764L0');
    } else {
      window.location.replace('https://apps.apple.com/us/app/walk-of-the-town-atx/id1562016005');
    }
  }


  return (
    <>
      <Helmet bodyAttributes={{ class: 'page-home' }}>
        <title>Walk of the Town</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <header className="hero">
        <div className="container">
          <div className="hero-content">
            <img src="/img/hero-logo.png" alt="Walk of the Town" className="hero-logo" />
            <h1>Stroll Through Austin History</h1>
            <p>Ready for tales of revenge, romance, redemption, and more? Take a self-guided tour of the Capital City downtown area to discover some of the places and people of our past. <strong><i>Let&rsquo;s go!</i></strong></p>
            <div className="platforms">
              <a href="https://play.google.com/store/apps/details?id=com.thirdrailplatform.wott&hl=en-US&ah=F6tzfVcrotp-66gEpo7HG6764L0"><img src="/img/play.png" alt="" /></a>
              <a href="https://apps.apple.com/us/app/walk-of-the-town-atx/id1562016005"><img src="/img/app.png" alt="" /></a>
            </div>
          </div>

          <div className="hero-img">
            <img src="/img/hero.png" alt="Screenshot of the app showing the Congress Avenue tour at the Paramount Theater." />
          </div>
        </div>
      </header>

      <div className="features">
        <section className="feature">
          <div className="container">
            <div className="feature-img">
              <img src="/img/choose-your-tour.png" alt="Screenshot of the app showing each leg of the tour." />
            </div>
            <div className="feature-content">
              <h2>Choose Your Adventure</h2>
              <p>From the legendary 6th Street to the grounds of the Texas State Capitol, choose from four distinct areas to explore.</p>
            </div>
          </div>
        </section>

        <section className="feature">
          <div className="container">
            <div className="feature-img">
              <img src="/img/step-back-in-time.png" alt="Screenshot of the app showing the Congress Avenue tour of a historical image of the Paramount Theater." />
            </div>
            <div className="feature-content">
              <h2>Step Back in Time</h2>
              <p>Listen in as our guides share little-known stories behind the city’s most iconic buildings, plus view historic photos dating as far back as the turn of the 20th century. </p>
            </div>
          </div>
        </section>

        <section className="feature">
          <div className="container">
            <div className="feature-img">
              <img src="/img/headphones.png" alt="Graphic of headphones connected to a phone." />
            </div>
            <div className="feature-content">
              <h2>Enjoy the Sights</h2>
              <p>Simply follow the narrated directions from stop to stop or use the convenient interactive map.</p>
            </div>
          </div>
        </section>
      </div>
      <section className="storytellers">
        <div className="container">
          <h2>Meet Your Storytellers</h2>
          <ul>
            <li>
              <img src="/img/joel.jpg" alt="Headshot of Joel Block" />
              <h3>Joel Block</h3>
              <p>Joel Block has been a host of <i>Walk of the Town</i> for more than four decades. As the long-time host of the <i>Star Date</i> and <i>Earth &amp; Sky</i> radio series, his voice is also known around the country. Joel’s voice has become synonymous with that of a trusting, conversational friend. He lives in Austin with his wife and owns The Block House recording studio.</p>
            </li>
            <li>
              <img src="/img/alejandra.jpeg" alt="Headshot of Alejandra Murga" />
              <h3>Alejandra Murga</h3>
              <p>Alejandra Murga started her career as an actress, singer, and dancer more than 30 years ago in Mexico City. She’s appeared in Mexican telenovelas, TV, and radio shows, as well as the Broadway musicals <i>CATS</i>, <i>Starlight Express</i>, and <i>Hello Dolly</i> in Mexico. In Austin, she’s performed in Spanish and English projects throughout the city. </p>
            </li>
            <li>
              <img src="/img/harrison.jpg" alt="Headshot of Harrison Eppright" />
              <h3>Harrison Eppright</h3>
              <p>Harrison Eppright is a born and raised East Austinite. With a passion for the history, he’s been a tour guide for Visit Austin since 1993. He also serves as the primary tour docent for Six Square: Austin’s Black Cultural District. Harrison combines his warm voice, deep laugh, and crisp delivery with his dramatic and historical skills for his tours and performances. </p>
            </li>
          </ul>
        </div>
      </section>

      <footer>
        <div className="container">
          <div className="footer-content">
            <img src="/img/wott.png" alt="Walk of the Town Austin, Texas" />
            <div className="platforms">
              <a href="https://play.google.com/store/apps/details?id=com.thirdrailplatform.wott&hl=en-US&ah=F6tzfVcrotp-66gEpo7HG6764L0"><img src="/img/play.png" alt="" /></a>
              <a href="https://apps.apple.com/us/app/walk-of-the-town-atx/id1562016005"><img src="/img/app.png" alt="" /></a>
            </div>
          </div>
          <div className="footer-img">
            <img src="/img/wott-lockup.png" alt="Graphic showing each leg of the tour." />
          </div>
        </div>
      </footer>

      <section className="supporters">
        <div className="container">
          <h2>A project of Leadership Austin. This historic preservation project is supported in part by the Heritage Tourism Division of the City of Austin Economic Development Department.</h2>

          <div className="supporters-list">
            <a className="supporter" href="https://www.austintexas.gov/department/heritage-tourism" target="_blank" rel="noreferrer">
              <img className="supporter-img" src="/img/supporters/CityOfAustin_HeritageTourismLogo-e1587496488659.png" alt="Heritage Tourism Division of the City of Austin Economic Development Department" />
            </a>
            <a className="supporter" href="https://leadershipaustin.org/" target="_blank" rel="noreferrer">
              <img className="supporter-img" src="/img/supporters/Leadership-Austin-Logo.png" alt="Leadership Austin" />
            </a>
            <a className="supporter" href="https://downtownaustin.com/" target="_blank" rel="noreferrer">
              <img className="supporter-img" src="/img/supporters/DAA_LOGO_RGB-Grey.png" alt="Downtown Austin Alliance" />
            </a>
            <a className="supporter" href="https://www.preservationaustin.org/" target="_blank" rel="noreferrer">
              <img className="supporter-img" src="/img/supporters/preservation-austin.png" alt="Preservation Austin" />
            </a>
            <a className="supporter" href="https://library.austintexas.gov/ahc/about-us" target="_blank" rel="noreferrer">
              <img className="supporter-img" src="/img/supporters/ahc_logo_new.png" alt="Austin History Center" />
            </a>
            <a className="supporter" href="https://www.austinlodging.org/" target="_blank" rel="noreferrer">
              <img className="supporter-img" src="/img/supporters/AHLA_Logo_white_small-3.png" alt="Austin Hotel &amp; Lodging Association" />
            </a>
            <a className="supporter" href="https://www.austintexas.org/" target="_blank" rel="noreferrer">
              <img className="supporter-img" src="/img/supporters/visit-austin.png" alt="Visit Austin" />
            </a>
            <a className="supporter" href="https://acgsa.org/" target="_blank" rel="noreferrer">
              <img className="supporter-img" src="/img/supporters/ACGSA.png" alt="Austin Concierge and Guest Services Assoc." />
            </a>
          </div>
        </div>
      </section>
    </>
  )
}
